<template>
  <div class="saleSampleDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.saleSample.id ? '编辑' : '新增' }}销售取样单</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="add()" v-if="!data.saleSample.id">保存</el-button>
          <el-button type="primary" @click="setById()" v-if="data.saleSample.id">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="客户">
            <el-select v-model="data.saleSample.custom" filterable>
              <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="仓储">
            <el-select v-model="data.saleSample.storage" filterable>
              <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="取样日期">
            <el-date-picker style="width: 100%;" v-model="data.saleSample.sampleDate" type="date" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="车号">
            <el-input v-model="data.saleSample.truckNumbers" :max="100" placeholder="多个车号，用英文逗号隔开"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="销售品名">
            <el-input v-model="data.saleSample.customNote" :max="100" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="取样数量">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.saleSample.sampleQuantity" />
          </el-form-item>
        </el-col>
        <el-divider content-position="left">指标</el-divider>
        <el-col :span="8">
          <el-form-item label="类型">
            <el-input v-model="data.saleSample.sampleType" :max="100" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="全水">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.saleSample.quanshui" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="内水">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.saleSample.neishui" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="灰分">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.saleSample.huifen" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="挥发分">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.saleSample.huifafen" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="全硫">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.saleSample.quanliu" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="固定碳">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.saleSample.gudingtan" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="热值·兆焦">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.saleSample.diweifareliangMj" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="热值·大卡">
            <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.saleSample.diweifareliangKc" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const show = reactive({
  customList: [],
  storageList: [],
  productList: [],
  transportCompanyList: [],
})
const pageParam = cache.getObject(cache.keys.pageParam + "saleSampleDetail")
const data = reactive({
  saleSample: pageParam ? JSON.parse(JSON.stringify(pageParam)) : {}
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'product' } }).then(res => {
  show.productList = res.dictList
})

const add = () => {
  const param = JSON.parse(JSON.stringify(data.saleSample))
  param.sampleDate = util.parseTime(param.sampleDate, '{y}-{m}-{d}')
  api.post('/backend/saleSample/add', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

const setById = () => {
  const param = { id: data.saleSample.id }
  param.sampleDate = util.parseTime(param.sampleDate, '{y}-{m}-{d}')
  param.truckNumbers = data.saleSample.truckNumbers
  param.storage = data.saleSample.storage
  param.custom = data.saleSample.custom
  param.customNote = data.saleSample.customNote
  param.sampleQuantity = data.saleSample.sampleQuantity
  param.sampleType = data.saleSample.sampleType
  param.quanshui = data.saleSample.quanshui
  param.neishui = data.saleSample.neishui
  param.huifen = data.saleSample.huifen
  param.huifafen = data.saleSample.huifafen
  param.quanliu = data.saleSample.quanliu
  param.gudingtan = data.saleSample.gudingtan
  param.diweifareliangMj = data.saleSample.diweifareliangMj
  param.diweifareliangKc = data.saleSample.diweifareliangKc
  param.saleAmount = null
  api.post('/backend/saleSample/setById', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}
</script>

<style lang="less">
.saleSampleDetail {
  margin: auto;
  max-width: 900px;
}
</style>